import React, { Component } from "react";

class PortfolioItemUXUI extends Component {
    render() {
        return (
            <div className="portfolioItem">

                <div className="project-name">
                    <p>
                        {this.props.name1}<br/>
                        {this.props.name2}
                    </p>
                </div>

                <div className="photoWrapper">
                    <img
                        alt={this.props.altText}
                        className={"thumbnail"}
                        onClick={() =>
                            this.props.viewSlidesPressed(
                                this.props.slidesFolder,
                                this.props.slides,
                                this.props.shortDescription,
                                this.props.scrollTop()
                            )
                        }
                        src={require(`../imgs/thumbnails/uxui/${this.props.thumbnail}`)}
                    />
                </div>

                <div className="project-details">

                    <div className="top-details">


                        <div className="view-slides" 
                            
                            onClick={() =>
                                this.props.viewSlidesPressed(
                                    this.props.slidesFolder,
                                    this.props.slides,
                                    this.props.description
                                )
                            }

                        >
                            View Slides &rsaquo;
                        </div>

                    </div>

                    <div className="description">
                        <p>
                            {this.props.description}
                        </p>
                    </div>

                </div>

            </div>
        );
    }
}

export default PortfolioItemUXUI;