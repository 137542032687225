import React, { Component } from "react";

import Introduction from "./Introduction";
import Portfolio from "./Portfolio";
import Skills from "./Skills";
import Resume from "./Resume";
import Education from "./Education";

import { library } from '@fortawesome/fontawesome-svg-core'

import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

class Home extends Component {

    constructor() {
        library.add(fab, fas, far)

        super();

    }

    render() {
        return (
            <article className={this.props.showHide}>
                <Introduction
                    className="introduction"
                />

                <Portfolio
                    className="portfolio"
                    portfolioDevDesignPressed={this.props.portfolioDevDesignPressed}
                    portfolioUXUIPressed={this.props.portfolioUXUIPressed}
                />

                <Skills
                    className="skills"
                />

                <Resume
                    className="resume"
                />

                <Education
                    className="education"
                />
            </article>
        );
    }
}

export default Home;