import React, { Component } from "react";

class navItems extends Component {

    render() {
        return (
            <ul>
                <li className="nav-item" onClick={this.props.homePressed}>
                    <span>01</span>
                    &lt; home &gt;
                </li>
                <li className="nav-item" onClick={this.props.portfolioPressed}>
                    <span>02</span>
                    &lt; portfolio &gt;
                </li>
                <li className="nav-item" onClick={this.props.resumePressed}>
                    <span>03</span>
                    &lt; resume &gt;
                </li>
                <li className="nav-item" onClick={this.props.skillsPressed}>
                    <span>04</span>
                    &lt; skills &gt;
                </li>
                <li className="nav-item" onClick={this.props.educationPressed}>
                    <span>05</span>
                    &lt; education &gt;
                </li>
                <li className="nav-item" onClick={this.props.contactPressed}>
                    <span>06</span>
                    &lt; contact &gt;
                </li>
            </ul>
        );
    }
}
  
export default navItems;