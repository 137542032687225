import classNames from 'classnames';

function Introduction() {

    return (

        <div className={classNames("section","introduction")}>
        
            <div className="introduction-text">
                <div className="introduction-small">
                    <span>&#9995;</span> Howdy! I'm Gray Gunter.
                </div>

                <div className="introduction-large">
                    I'm a&nbsp;
                    <span className="introduction-highlight">UX/UI designer</span> and&nbsp;<span className="introduction-highlight">front-end web developer</span>&nbsp;with over <span className="introduction-highlight">15 years of experience</span>.
                </div>

                <div className="introduction-details">
                    Based in Columbia, South Carolina, I have worked with private companies of all sizes and government agencies on projects large and small. I have worked on-site, remotely and traveling to clients. I enjoy designing and coding. When people ask me what I do for a living I usually reply, “…stuff on the internet.” 
                </div>
            </div>
            <div className="profile">
                <img 
                    src={require(`../imgs/profile_real.jpg`)} />
            </div>
        </div>
    );
}
  
export default Introduction;