import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import resume from "../docs/GrayGunter_resume.pdf";

function Resume() {

    return (
        <div className={classNames("section","resume")}>

            <div className="section-title">
                <span className="code">
                    <span className="blue">&#123;</span> 
                    <span className="yellow"> /* </span>
                    <span className="red">resume</span>
                    <span className="yellow"> */ </span>
                    <span className="blue">&#125;</span>
                    <span className="cursor">|</span>
                </span>
            </div>

            <div className="resume-and-linkedIn">

                <a 
                    className="resume-download"
                    href={resume}
                    rel="noreferrer"
                    target="_blank">
                    <FontAwesomeIcon icon="fa-solid fa-file-arrow-down"/>
                    &nbsp;&nbsp;Download Full Resume
                </a>

                <a 
                    className="linkedIn"
                    href="https://www.linkedin.com/in/graygunter/"
                    rel="noreferrer"
                    target="_blank">
                    <FontAwesomeIcon icon="fa-brands fa-linkedin"/>
                    &nbsp;&nbsp;graygunter
                </a>

            </div>

            <div className="resume-container">

                <div className={classNames("resume-item", "scdhhs")}>
                    <div className="resume-item-headline">
                        <div className="job-employer">
                            <span>South Carolina Department of</span>
                            <span>Health and Human Services</span>
                        </div>
                        <div className="job-title-and-date">
                            <div className="job-title">
                                <FontAwesomeIcon icon="fa-solid fa-gears"/>User Experience and<br/>User Interface Design
                            </div>
                            <div className="job-date">
                                <FontAwesomeIcon icon="fa-regular fa-calendar-days"/>
                                August 2015 –<br/>
                                January 2024
                            </div>
                        </div>
                    </div>
                    <ul className="job-highlights">
                        <li>Redesigned Public Website</li>
                        <li>Enterprise Advanced Planning</li>
                        <li>Medicaid Enterprise Services</li>
                        <li>Accessibility Compliance</li>
                    </ul>
                    <p className="job-details">
                        My role with SCDHHS marked my first time as a full-time government contractor (through <a href="https://www.dppit.com/" rel="noreferrer" target="_blank">DP Professionals</a>) as well as working purely as a designer with zero programming responsibilities. I was responsible for developing user personas, workflows, wire friends and rapid prototyping clickable mockups. I created application concepts for internal use and public-facing products based on user requirements and scenarios.
                    </p>
                    <p className="job-details">
                        Also I was asked to monitor brand standards for adherence to established style guides and  generating new standards for user interaction across multiple products and platforms. Lastly I was tasked with reviewing any projects for use by the public for adherence to accessibility compliance law.
                    </p>
                </div>

                <div className={classNames("resume-item", "graybear")}>
                    <div className="resume-item-headline">
                        <div className="job-employer">
                            Graybear LLC
                        </div>
                        <div className="job-title-and-date">
                            <div className="job-title">
                                <FontAwesomeIcon icon="fa-solid fa-user-tie"/>Owner
                            </div>
                            <div className="job-date">
                                <FontAwesomeIcon icon="fa-regular fa-calendar-days" />
                                January 2011 –<br/>
                                Present
                            </div>
                        </div>
                    </div>
                    <ul className="job-highlights">
                        <li>Freelance</li>
                        <li>Design</li>
                        <li>Development</li>
                        <li>WordPress</li>
                    </ul>
                    <p className="job-details">
                        I use Graybear as my brand for accepting freelance projects. This can range from solo work to temporarily joining a larger team. I’ve worked with a variety of artists, agencies and development pipelines. In addition I have worked with small companies, enterprise level companies and government agencies both remotely and traveling for on-site work.
                    </p>
                    <p className="job-details">
                        <strong>Featured Clients:</strong> Netflix, GoDaddy, Ford Motor Company, Sony Pictures, Paramount Pictures.
                    </p>
                </div>

            </div>

            <div className="resume-container">

                <div className={classNames("resume-item", "ironyard")}>
                    <div className="resume-item-headline">
                        <div className="job-employer">
                            The Iron Yard
                        </div>
                        <div className="job-title-and-date">
                            <div className="job-title">
                                <FontAwesomeIcon icon="fa-solid fa-person-chalkboard" /> 
                                Instructor
                            </div>
                            <div className="job-date">
                                <FontAwesomeIcon icon="fa-regular fa-calendar-days" />
                                February 2017 –<br/>
                                June 2017
                            </div>
                        </div>
                    </div>
                    <ul className="job-highlights">
                        <li>Teaching</li>
                        <li>JavaScript</li>
                        <li>HTML</li>
                        <li>CSS</li>
                    </ul>
                    <p className="job-details">
                        The Iron Yard was a condensed, immersive course designed to prepare post high school graduates with little to no experience in web development for a career in the industry. Over twelve weeks I taught a full-time class in front-end web development consisting of the fundamentals of HTML, CSS and JavaScript the first half the semester and modern developer tools including SASS and ReactJS during the second half.
                    </p>
                    <p className="job-details">
                        I taught at the school’s Columbia, SC campus, with an additional two weeks guest lecturing at The Iron Yard’s location in Charleston, SC.
                    </p>
                </div>

                <div className={classNames("resume-item", "cyberwoven")}>
                    <div className="resume-item-headline">
                        <div className="job-employer">
                            Cyberwoven
                        </div>
                        <div className="job-title-and-date">
                            <div className="job-title">
                                <FontAwesomeIcon icon="fa-solid fa-keyboard"/>Web Designer and<br/>Web Developer
                            </div>
                            <div className="job-date">
                                <FontAwesomeIcon icon="fa-regular fa-calendar-days" />
                                May 2008 –<br/>
                                December 2010
                            </div>
                        </div>
                    </div>
                    <ul className="job-highlights">
                        <li>Adobe</li>
                        <li>JavaScript</li>
                        <li>HTML</li>
                        <li>CSS</li>
                    </ul>
                    <p className="job-details">
                        My first full-time job in front-end web development came with the digital firm Cyberwoven immediately after I finished grad school. The firm was local to my hometown of Columbia, SC, and I was the agency’s seventeenth hire. My day-to-day responsibilities focused on the front-end coding and designing of a variety websites around a proprietary Content Management System.
                    </p>
                    <p className="job-details">
                        <strong>Featured Clients: </strong>University of South Carolina’s Moore Business School, South Carolina Bank & Trust, North Carolina Bank & Trust,  Zeus and the office building 1441 Main. 
                    </p>
                </div>

            </div>

        </div>
    );
}
  
export default Resume;