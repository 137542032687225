import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

function Education() {

    return (
        <div className={classNames("section","education")}>

            <div className="section-title">
                <span className="code">
                    <span className="blue">&#123;</span> 
                    <span className="yellow"> /* </span>
                    <span className="red">education</span>
                    <span className="yellow"> */ </span>
                    <span className="blue">&#125;</span>
                    <span className="cursor">|</span>
                </span>
            </div>

            <div className="education-container">

                <div className="education-item">
                    <div className="education-icon">
                        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080.51 677.12">
                            <g id="Layer_1-2" data-name="Layer 1">
                                <path class="cls-1" d="M1080.49,215.5H426.35v175.96l97.98-.02,9.34-49.86s20.49-.04,30.75.02c-6.28,25.84-20.23,49.66-39.59,67.9-25.69,24.65-59.32,39.94-93.98,47-41.24,8.42-84.23,4.89-124.6-6.19-35.89-10.12-70.65-27.33-96.92-54.23-19.52-19.88-33.25-45.68-37.19-73.38-5.07-36.13,2.92-73.86,21.75-105.05,21.9-36.32,57.42-63.26,96.88-78.01,41.41-15.77,87.41-18.22,130.56-8.64,47,10.09,91.08,32.6,127.29,64.18,36.34.09,109.03.04,109.03.04l-.02-195.23h-113.34s0,36.15,0,54.23C481.02,17.69,407.23.69,334.43,1.88c-68.94,1.43-137.06,23.54-194.37,61.71-44.94,29.98-83.62,70.11-109.22,117.87C12.22,215.98,1.23,254.67,0,293.93v11.54c1.15,41.95,13.77,83.41,35.98,119.01,31.54,50.98,80.23,89.95,134.48,114.58,38.29,17.46,79.62,28,121.51,31.62,36.54,3.44,73.27.02,109.48-5.09,51.7-7.62,103.21-20.29,150.29-43.45,0,6.37.02,19.15.02,19.15l119.8-.02v-199.69h28.78v222.24h-57.59l.04,113.3,266.08-.06-.02-113.27-57.35.02v-222.24l121.55.04,8.97,49.84h98.48l-.02-175.96Z"/>
                            </g>
                        </svg>
                    </div>
                    M.S. Digital Media,<br/>
                    Georgia Tech
                </div>

                <div className="education-item">
                    <div className="education-icon">
                        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080.51 677.12">
                            <g id="Layer_1-2" data-name="Layer 1">
                                <path class="cls-1" d="M1080.49,215.5H426.35v175.96l97.98-.02,9.34-49.86s20.49-.04,30.75.02c-6.28,25.84-20.23,49.66-39.59,67.9-25.69,24.65-59.32,39.94-93.98,47-41.24,8.42-84.23,4.89-124.6-6.19-35.89-10.12-70.65-27.33-96.92-54.23-19.52-19.88-33.25-45.68-37.19-73.38-5.07-36.13,2.92-73.86,21.75-105.05,21.9-36.32,57.42-63.26,96.88-78.01,41.41-15.77,87.41-18.22,130.56-8.64,47,10.09,91.08,32.6,127.29,64.18,36.34.09,109.03.04,109.03.04l-.02-195.23h-113.34s0,36.15,0,54.23C481.02,17.69,407.23.69,334.43,1.88c-68.94,1.43-137.06,23.54-194.37,61.71-44.94,29.98-83.62,70.11-109.22,117.87C12.22,215.98,1.23,254.67,0,293.93v11.54c1.15,41.95,13.77,83.41,35.98,119.01,31.54,50.98,80.23,89.95,134.48,114.58,38.29,17.46,79.62,28,121.51,31.62,36.54,3.44,73.27.02,109.48-5.09,51.7-7.62,103.21-20.29,150.29-43.45,0,6.37.02,19.15.02,19.15l119.8-.02v-199.69h28.78v222.24h-57.59l.04,113.3,266.08-.06-.02-113.27-57.35.02v-222.24l121.55.04,8.97,49.84h98.48l-.02-175.96Z"/>
                            </g>
                        </svg>
                    </div>
                    B.S. Industrial Design,<br/>
                    Georgia Tech
                </div>

            </div>


        </div>
    );
}
  
export default Education;