import React, { Component } from "react";

class PortfolioItem extends Component {
    render() {
        return (
            <div className="portfolioItem">

                <div className="project-name">
                    <p>
                        {this.props.projectLink ? (
                            <a
                                href={this.props.projectLink}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {this.props.name} &rsaquo;
                            </a>
                            ) : (
                            <a
                                href={"https://vimeo.com/" + this.props.vimeoID}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {this.props.name} &rsaquo;
                            </a>
                        )}
                    </p>
                </div>

                <div className="iframeWrapper">
                    <iframe
                        allowFullScreen
                        height="auto"
                        mozallowfullscreen="true"
                        src={
                        "https://player.vimeo.com/video/" +
                        this.props.vimeoID +
                        "?title=0&amp;byline=0&amp;portrait=0"
                        }
                        title={this.props.id}
                        webkitallowfullscreen="true"
                        width="100%"
                    ></iframe>
                    <script src="https://player.vimeo.com/api/player.js"></script>
                </div>

                <div className="project-details">

                    <div className="top-details">

                        <div className="client-name">
                            {this.props.clientName ? (
                                this.props.clientLink ? (
                                    <p>
                                        <span>Client </span>/&nbsp;
                                        <a
                                            href={this.props.clientLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {this.props.clientName} &rsaquo;
                                        </a>
                                    </p>
                                ) : (
                                    <p>
                                        <span>Client </span>/&nbsp;{this.props.clientName}
                                    </p>
                                )
                            ) : null}
                        </div>

                        <div className="agency-name">

                            {this.props.agencyName ? (
                                this.props.agencyLink ? (
                                    <p>
                                        <span>Agency </span>/&nbsp;
                                        <a
                                        href={this.props.agencyLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        >
                                        {this.props.agencyName} &rsaquo;
                                        </a>
                                    </p>
                                ) : (
                                    <p>
                                        <span>Agency /</span>&nbsp;{this.props.agencyName}
                                    </p>
                                )
                            ) : null}

                        </div>

                        <div className="project-role">
                            {this.props.role ? (
                                <p>
                                    <span>Role </span>/&nbsp;
                                    {this.props.role}
                                </p>
                            ) : null}
                        </div>

                        {this.props.slidesFolder ? (

                                <div className="view-slides" 
                                    onClick={() =>
                                        this.props.viewSlidesPressed(
                                            this.props.slidesFolder,
                                            this.props.slides,
                                            this.props.shortDescription,
                                            this.props.scrollTop()
                                        )
                                    }
                                >
                                    View Slides &rsaquo;
                                </div>
                            ) :
                            null
                        }

                    </div>

                    <div className="description">
                        <p>
                            {this.props.description}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default PortfolioItem;