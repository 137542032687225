import React, { Component } from "react";
import NavItems from './NavItems';

class Nav extends Component {

    render() {
        return (
            <nav>
                <NavItems 
                    contactPressed={this.props.contactPressed}
                    educationPressed={this.props.educationPressed}
                    homePressed={this.props.homePressed}
                    portfolioPressed={this.props.portfolioPressed}
                    resumePressed={this.props.resumePressed}
                    skillsPressed={this.props.skillsPressed}    
                    
                />
            </nav>
        );
    }
}
  
export default Nav;