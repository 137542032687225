import React, { Component } from "react";
import classNames from 'classnames';
import CarouselContainer from "./CarouselContainer";
import PortfolioItem from "./PortfolioItem";
import PortfolioItemUXUI from "./PortfolioItemUXUI";

class PortfolioListing extends Component {

    constructor() {
        
        super();

        this.closeSlidesPressed = this.closeSlidesPressed.bind(this);
        this.generateProjects = this.generateProjects.bind(this);
        this.generateProjectsUXUI = this.generateProjectsUXUI.bind(this);
        this.viewSlidesPressed = this.viewSlidesPressed.bind(this);

        this.state = {
            isSlideShow: false,
            slidesDescription: "",
            slides: [],
            slidesFolder: ""
        };
    }

    closeSlidesPressed() {

        this.setState({isSlideShow: false});

    }

    viewSlidesPressed(slidesFolder, slides, shortDescription) {
        
        this.setState({
            isSlideShow: true,
            slidesDescription: shortDescription,
            slides: slides,
            slidesFolder: slidesFolder
        });
    }

    generateProjects(project, index) {

        return (
            <PortfolioItem
                agencyLink={project["agency-link"]}
                agencyName={project["agency-name"]}
                clientLink={project["client-link"]}
                clientName={project["client-name"]}
                description={project["description"]}
                key={"portfolioItem" + index}
                name={project["name"]}
                projectLink={project["link"]}
                role={project["role"]}
                scrollTop={this.props.scrollTop}
                shortDescription={project["shortDescription"]}
                slides={project["slides"]}
                slidesFolder={project["slides-folder"]}
                viewSlidesPressed={this.viewSlidesPressed}
                vimeoID={project["vimeo-id"]}
            />
        );
    }

    generateProjectsUXUI(project, index) {

        console.log(project["slides"]);
        console.log(project["slides-folder"]);
        console.log(project["description"]);

        return (
            
            <PortfolioItemUXUI
                altText={project["altText"]}
                description={project["description"]}
                key={"portfolioItemUXUI" + index}
                name1={project["name1"]}
                name2={project["name2"]}
                thumbnail={project["thumbnail"]}
                scrollTop={this.props.scrollTop}
                shortDescription={project["shortDescription"]}
                slides={project["slides"]}
                slidesFolder={project["slides-folder"]}
                viewSlidesPressed={this.viewSlidesPressed}
            />
        );
    }

    showProjectsListing() {

        return (
            
            <div className="section">

                <div className="section-title">
                            
                    <span className="code">
                        <span className="blue">&#123;</span> 
                        <span className="yellow"> /* </span>
                        <span className="red">
                            {this.props.isPortfolioDevDesign ?
                                "Development & Design"
                                :
                                "User Experience & User Interface"
                            }
                        </span>
                        <span className="yellow"> */ </span>
                        <span className="blue">&#125;</span>
                        <span className="cursor">|</span>
                    </span>

                </div>

                <div className="portfolioProjectsList">
                    
                    {this.props.isPortfolioDevDesign ?
                        Array.from(this.props.projectsData).map(this.generateProjects)
                        :
                        Array.from(this.props.projectsData).map(this.generateProjectsUXUI)
                    }
                
                </div>

            </div>

        );
    }

    showProjectSlideShow() {

        return (

            <div className="section">

                <CarouselContainer
                    closeSlidesPressed={this.closeSlidesPressed}
                    portfolioType={
                        this.props.isPortfolioDevDesign ? "devDesign" : "uxui"
                    } 
                    slidesDescription={this.state.slidesDescription}
                    slides={this.state.slides}
                    slidesFolder={this.state.slidesFolder}
                />

            </div>

        );
    }

    render() {
        return (
            
            <article className={classNames(this.props.showHide, "portfolio")}>
                {this.state.isSlideShow ? 
                    this.showProjectSlideShow() : this.showProjectsListing()
                }
            </article>

        );
    }
}

export default PortfolioListing;