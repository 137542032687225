import React, { Component } from "react";
import classNames from 'classnames';
import CognitoForm from '@tylermenezes/cognitoforms-react';

class Contact extends Component {
    render() {
        
        return (
            <article className={classNames(this.props.showHide, "contact")}>

                <div className="section">

                    <div className="section-title">
                        
                        <span className="code">
                            <span className="blue">&#123;</span> 
                            <span className="yellow"> /* </span>
                            <span className="red">contact</span>
                            <span className="yellow"> */ </span>
                            <span className="blue">&#125;</span>
                            <span className="cursor">|</span>
                        </span>

                    </div>

                    <CognitoForm
                        formId={1}
                        accountId={`213mxnhu9EiHnPTd9v0xfA`}
                    />

                </div>

            </article>
        );
    }
}

export default Contact;