function Footer() {

    return (
        <footer>
            Made with 
            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 116 106">
                <g id="svg2">
                    <path id="path4" class="cls-1" d="M58,17C52,7,42,0,30,0,13,0,0,13,0,30c0,33,18,38,58,76,40-38,58-43,58-76,0-17-13-30-30-30-12,0-22,7-28,17Z"/>
                </g>
            </svg>    
            in ReactJS.&nbsp;
            Copyright &copy; Graybear LLC&nbsp;
            {new Date().getFullYear()}
        </footer>
    );
}
  
export default Footer;