import React, { Component } from "react";

import classNames from 'classnames';

class Portfolio extends Component {

    render() {
        return (
            <div className={classNames("section","portfolio")}>

                <div className="section-title">
                    <span className="code">
                        <span className="blue">&#123;</span> 
                        <span className="yellow"> /* </span>
                        <span className="red">portfolio</span>
                        <span className="yellow"> */ </span>
                        <span className="blue">&#125;</span>
                        <span className="cursor">|</span>
                    </span>
                </div>

                <div className="portfolio-container">

                    <div 
                        className={classNames("portfolio-item","dev-design")}
                        onClick={this.props.portfolioDevDesignPressed}>

                        <div className="inner-text">                        
                            Development<br/>
                            &<br/> 
                            Design
                        </div>
                    </div>

                    <div 
                        className={classNames("portfolio-item","ux-ui")}
                        onClick={this.props.portfolioUXUIPressed}>

                        <div className="inner-text">                        
                            User Experience<br/>
                            &<br/>
                            User Interface
                        </div>
                    </div>

                </div>
                
            </div>
        );
    }
}
  
export default Portfolio;