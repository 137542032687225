import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

var Carousel = require('react-responsive-carousel').Carousel;

class CarouselContainer extends Component {
    
    constructor() {
        
        super();

        this.generateSlides = this.generateSlides.bind(this);

    }
    
    generateSlides(fileName, index) {

        return (

            <div
                key={index}>

                <img 
                    alt={this.props.slidesDescription} 
                    src={require(`../imgs/slides/${this.props.portfolioType}/${this.props.slidesFolder}/${fileName}`)}/>
                <p className="legend">{this.props.slidesDescription}</p>

            </div>

        );

    }
    
    render() {
        
        return (
            <div className="carouselContainer">

                <div className="closeButton"
                    onClick={() =>
                        this.props.closeSlidesPressed()
                    }
                >                
                    <FontAwesomeIcon icon="fa-solid fa-square-xmark" /> Close
                </div>

                <Carousel
                autoPlay={true}
                infiniteLoop={true}
                interval={2000}
                showArrows={true}
                transitionTime={1}>
                    {this.props.slides.map(this.generateSlides)}
                </Carousel>
            </div>
        );
    }
}

export default CarouselContainer;