import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

function Skills() {

    return (
        <div className={classNames("section","skills")}>

            <div className="section-title">
                <span className="code">
                    <span className="blue">&#123;</span> 
                    <span className="yellow"> /* </span>
                    <span className="red">skills</span>
                    <span className="yellow"> */ </span>
                    <span className="blue">&#125;</span>
                    <span className="cursor">|</span>
                </span>
            </div>
            <div className={classNames("skills-container","uxui")}>
                <div className="skills-title">
                    <FontAwesomeIcon icon="fa-solid fa-hand-pointer" /> UX/UI
                </div>
                <div className="skill-block">
                    <div className={classNames("skill-item", "figma")}>
                        <div className="skill-icon">
                            <FontAwesomeIcon icon="fa-brands fa-figma" />
                        </div> 
                        Figma
                    </div>
                    <div className={classNames("skill-item", "adobexd")}>
                        <div className="skill-icon">
                            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1079.49 1079.49">
                            <g id="Layer_1-2" data-name="Layer 1">
                                <path class="cls-1" d="M717.62,518.53c-46.51,35.9-50.18,113.42-11.83,153.4,25.29,26.11,67.72,31.82,103.63,24.07v-196.23s-48.14-14.69-91.79,18.77Z"/>
                                <path class="cls-1" d="M866.53,0H213.37C95.87,0,0,95.87,0,212.96v653.57c0,117.09,95.87,212.96,213.37,212.96h653.16c117.09,0,212.96-95.87,212.96-212.96V212.96c0-117.09-95.87-212.96-212.96-212.96ZM454.89,775.15l-94.65-176.65-96.28,176.65h-110.15l143.2-232.54-135.85-232.14h111.78l93.02,171.76,93.43-171.76h104.03l-136.67,226.02,147.28,238.66h-119.13ZM906.51,753.93c.82,0-190.11,80.37-286.4-28.97-69.76-79.15-48.55-213.78,36.72-273.34,44.88-31.41,99.14-37.94,153.4-33.05l-.82-146.87s97.1-.82,97.1,0-2.04,482.22,0,482.22Z"/>
                            </g>
                            </svg>
                        </div>
                        Adobe XD
                    </div>
                    <div className={classNames("skill-item", "sketch")}>
                        <div className="skill-icon">
                            <FontAwesomeIcon icon="fa-brands fa-sketch" />
                        </div>
                        Sketch
                    </div>
                    <div className={classNames("skill-item", "uxpin")}>
                        <div className="skill-icon">
                            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">

                                <g id="Layer_1-2" data-name="Layer 1">
                                    <path class="cls-1" d="M585.94,410.9h-28.89v90.7h28.89c11.4,0,20.68-3.88,27.81-11.65,7.13-7.76,10.7-19.41,10.7-34.95s-3.57-26.47-10.7-33.52c-7.13-7.05-16.41-10.58-27.81-10.58Z"/>
                                    <path class="cls-1" d="M0,0v1024h1024V0H0ZM339.45,550c0,12.91-1.32,24.98-3.94,36.21-2.63,11.24-6.93,21.09-12.91,29.58-5.98,8.49-13.8,15.18-23.48,20.08s-21.57,7.35-35.67,7.35-25.87-2.45-35.31-7.35c-9.44-4.9-17.15-11.59-23.12-20.08-5.98-8.48-10.22-18.34-12.73-29.58-2.51-11.23-3.76-23.3-3.76-36.21v-166.7h32.26v163.47c0,8.6.66,16.91,1.97,24.92,1.31,8.01,3.58,15.12,6.81,21.33,3.23,6.22,7.65,11.17,13.26,14.88,5.61,3.71,12.73,5.56,21.33,5.56s15.65-1.85,21.15-5.56c5.49-3.7,9.86-8.66,13.08-14.88,3.23-6.21,5.5-13.32,6.81-21.33,1.31-8,1.97-16.31,1.97-24.92v-163.47h32.26v166.7ZM477.47,637.11l-47.68-106.83-47.68,106.83h-34.06l64.17-134.08-57.72-119.74h34.06l42.3,94.64,41.59-94.64h33.34l-57.72,120.1,64.53,133.72h-35.13ZM651.34,486.9c-3.58,9.32-8.42,17.03-14.52,23.12-6.1,6.09-13.03,10.7-20.79,13.8-7.77,3.11-15.95,4.66-24.56,4.66h-34.42v108.62h-32.26v-253.81h64.34c7.67,0,15.52,1.14,23.54,3.41,8.03,2.27,15.28,6.16,21.75,11.65,6.47,5.5,11.8,12.85,16,22.05,4.19,9.2,6.29,20.61,6.29,34.24,0,12.19-1.79,22.94-5.38,32.26ZM697.59,637.11h-30.47v-179.96h30.47v179.96ZM695.97,418.43c-3.71,3.83-8.19,5.74-13.44,5.74s-10.04-1.91-13.62-5.74c-3.58-3.82-5.38-8.72-5.38-14.7s1.79-10.58,5.38-14.52,8.12-5.92,13.62-5.92,10.04,1.97,13.62,5.92,5.38,8.78,5.38,14.52-1.85,10.88-5.56,14.7ZM854.96,637.11h-30.83v-114.98c0-5.03-.24-10.18-.72-15.45-.48-5.27-1.61-10.06-3.41-14.37-1.79-4.31-4.36-7.79-7.71-10.42-3.35-2.63-7.89-3.95-13.62-3.95-5.02,0-9.68,1.38-13.98,4.13-4.3,2.76-8.01,6.59-11.11,11.49-3.11,4.91-5.5,10.78-7.17,17.61-1.67,6.83-2.51,14.31-2.51,22.46v103.48h-30.47v-134.44c0-7.17-.12-14.88-.36-23.12-.24-8.25-.6-15.71-1.08-22.41h29.04c.72,5.98,1.25,11.95,1.61,17.92.36,5.98.54,10.4.54,13.26h.72c3.34-10.51,8.9-19.36,16.67-26.53,7.76-7.17,17.26-10.75,28.5-10.75,9.56,0,17.39,2.03,23.48,6.09,6.09,4.07,10.75,9.44,13.98,16.13,3.23,6.69,5.43,14.34,6.63,22.94,1.19,8.6,1.79,17.33,1.79,26.17v114.72Z"/>
                                </g>
                            </svg>
                        </div>
                        UXPin
                    </div>
                </div>
            </div>

            <div className={classNames("skills-container","code")}>

                <div className="skills-title">
                    <FontAwesomeIcon icon="fa-solid fa-laptop-code" /> Code
                </div>
                <div className="skill-block">
                    <div className={classNames("skill-item", "html")}>
                        <div className="skill-icon">
                            <FontAwesomeIcon icon="fa-brands fa-html5" />
                        </div>
                        HTML
                    </div>
                    <div className={classNames("skill-item", "sass")}>
                        <div className="skill-icon">
                            <FontAwesomeIcon icon="fa-brands fa-sass" />
                        </div>
                        Sass
                    </div>
                    <div className={classNames("skill-item", "javascript")}>
                        <div className="skill-icon">
                            <FontAwesomeIcon icon="fa-brands fa-js" />
                        </div>    
                        JavaScript
                    </div>
                    <div className={classNames("skill-item", "react")}>
                        <div className="skill-icon">
                            <FontAwesomeIcon icon="fa-brands fa-react" /> 
                        </div>
                        React
                    </div>
                </div>
                <div className="skill-block">
                <div className={classNames("skill-item", "node")}>
                        <div className="skill-icon">
                            <FontAwesomeIcon icon="fa-brands fa-node" /> 
                        </div>
                        Node
                    </div>
                    <div className={classNames("skill-item", "github")}>
                        <div className="skill-icon">
                            <FontAwesomeIcon icon="fa-brands fa-github" /> 
                        </div>    
                        GitHub
                    </div>
                </div>
            </div>
            
            <div className={classNames("skills-container", "design")}>

                <div className="skills-title">
                    <FontAwesomeIcon icon="fa-solid fa-pencil" /> Design
                </div>
                <div className="skill-block">
                    <div className={classNames("skill-item", "photoshop")}>
                        <div className="skill-icon">
                            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384.37 375.69">
                                <g id="Layer_1-2" data-name="Layer 1">
                                    <path class="cls-1" d="M149.45,128.46h-27.42v51.24c22.63,1.59,51.19,4.53,51.19-25.62,0-13.15-10.27-25.62-23.77-25.62Z"/>
                                    <path class="cls-1" d="M319.1,0H65.27C29.37,0,0,29.37,0,65.27v245.16c0,35.9,29.37,65.27,65.27,65.27h253.83c35.9,0,65.27-29.37,65.27-65.27V65.27c0-35.9-29.37-65.27-65.27-65.27ZM122.02,209.22v53.3c0,1.82-1.48,3.3-3.3,3.3h-28.13c-1.82,0-3.3-1.48-3.3-3.3V100.02c0-1.82,1.48-3.3,3.3-3.3h58.86v.02c32.94.69,59.42,26.1,59.42,57.34,0,53.21-46.19,59.24-86.84,55.14ZM311.64,251.8s-.03.03-.04.05c-16.3,21.22-54.91,20.35-83.45,9.72-1.32-.49-2.15-1.69-2.15-3.1v-24.09c0-1.2.59-2.24,1.63-2.85,1.04-.61,2.23-.62,3.28-.04,7.47,4.16,15.83,6.36,33.07,8.41,21.06,2.51,27.28-11.86,9.42-19.35-8.72-3.65-51.64-11.57-46.22-53.66.02-.17.05-.32.1-.49,10.11-35.18,56.42-34.84,82.76-26.06,1.38.46,2.27,1.7,2.26,3.15l-.12,24.72c0,1.17-.56,2.17-1.56,2.79-.99.62-2.14.68-3.19.17-15.38-7.46-35.64-10.14-42.18-5.74-12.59,8.46-2.38,15.74,26.27,26.53,28.45,10.71,35.4,40.73,20.11,59.83Z"/>
                                </g>
                            </svg>
                        </div>
                        Photoshop
                    </div>
                    <div className={classNames("skill-item", "illustrator")}>
                        <div className="skill-icon">
                            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080.51 1055.51">
                            <g id="Layer_1-2" data-name="Layer 1">
                                <g>
                                <polygon points="405.65 576.91 517.02 576.91 460 392.09 405.65 576.91"/>
                                <path d="M886.45,0H194.05C86.88,0,0,86.88,0,194.05v667.41c0,107.17,86.88,194.05,194.05,194.05h692.4c107.17,0,194.05-86.88,194.05-194.05V194.05c0-107.17-86.88-194.05-194.05-194.05ZM583.6,783.96l-38.19-115.08-167.17,1.21-37.48,113.87h-104.32l149-413.27c7.26-20.14,10.97-41.38,10.97-62.79h127.34l170.16,476.06h-110.31ZM836.68,783.96h-98.85v-361.3h98.85v361.3ZM787.26,385.16c-31.37,0-56.81-25.43-56.81-56.81s25.43-56.81,56.81-56.81,56.81,25.43,56.81,56.81-25.43,56.81-56.81,56.81Z"/>
                                </g>
                            </g>
                            </svg>
                        </div>
                        Illustrator
                    </div>
                    <div className={classNames("skill-item", "indesign")}>
                        <div className="skill-icon">
                            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080.51 1055.51">
                            <g id="Layer_1-2" data-name="Layer 1">
                                <g>
                                <path d="M565.25,586.34v29.4c0,48.36,39.2,87.55,87.55,87.55h38.56v-204.51h-38.56c-48.36,0-87.55,39.2-87.55,87.55Z"/>
                                <path d="M886.45,0H194.05C86.88,0,0,86.88,0,194.05v667.4c0,107.17,86.88,194.05,194.05,194.05h692.4c107.17,0,194.05-86.88,194.05-194.05V194.05c0-107.17-86.88-194.05-194.05-194.05ZM389.14,788.51h-103.39v-478.33h103.39v478.33ZM794.76,413.57v336.31c-1.14,30.74-103.39,38.63-103.39,38.63h-63.13c-88.64,0-160.51-71.86-160.51-160.51v-53.92c0-88.64,71.86-160.51,160.51-160.51h63.13v-146.57h103.39v146.57Z"/>
                                </g>
                            </g>
                            </svg>
                        </div>
                        InDesign
                    </div>
                    <div className={classNames("skill-item", "aftereffects")}>
                        <div className="skill-icon">
                            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080.51 1052.1">
                                <g id="Layer_1-2" data-name="Layer 1">
                                    <g>
                                    <path d="M300.18,545.71h111.72c-19.12-61.51-38.16-122.75-56.68-182.31-18.02,59.69-36.5,120.9-55.04,182.31Z"/>
                                    <path d="M763.81,449.23c-36.48,0-66.06,29.49-66.06,65.85h132.12c0-36.36-29.58-65.85-66.06-65.85Z"/>
                                    <path d="M886.77,0H193.74C86.74,0,0,86.74,0,193.74v664.62c0,107,86.74,193.74,193.74,193.74h693.03c107,0,193.74-86.74,193.74-193.74V193.74c0-107-86.74-193.74-193.74-193.74ZM574.32,744.91c-10.65.09-21.3.03-31.94.03-18.93,0-37.86,0-56.79,0-8.08,0-8.76-.48-11.3-7.9-11.18-32.63-22.44-65.24-33.46-97.93-1.34-3.97-3.06-5.45-7.43-5.44-51.55.16-103.1.16-154.65,0-4.15-.01-5.8,1.3-7.02,5.15-10.24,32.23-20.71,64.38-31.1,96.56-2.68,8.31-3.91,9.22-12.6,9.22-25.18,0-50.37,0-75.55,0-10.42,0-11.51-1.49-8.12-11.16,33.39-95.46,66.82-190.91,100.18-286.38,14.88-42.57,29.97-85.07,44.18-127.87,3.22-9.71,3.4-20.48,4.49-30.81.76-7.14.65-7.46,7.68-7.47,18.09-.01,36.17,0,54.26,0s35.16,0,52.73,0c8.19,0,8.22,0,10.89,7.46,53.22,148.95,106.43,297.9,159.64,446.85.4,1.11.83,2.22,1.06,3.37.92,4.62-.33,6.26-5.13,6.3ZM901.63,580.87c-40.33-.13-80.67-.26-120.99-.4-27.63-.09-55.26-.19-82.88-.28,11.74,122.18,191.67,67.49,191.67,67.49v76.95c-57.88,25.18-128.73,19.86-128.73,19.86-161.67-12.04-162.03-164.3-162.03-164.3-.12-49.68,8.75-103.26,39.12-144.05,37.98-51,104.48-65.44,164.9-54.6,16.99,3.04,33.52,8.77,48.43,17.43,27.83,16.14,47.56,42.01,58.06,72.13,6.74,19.34,9.97,39.93,11.01,60.64.6,12.2.7,49.18.7,49.18,0,0-15.02-.04-19.26-.06Z"/>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        After Effects
                    </div>
                </div>
            </div>
        </div>
    );
}
  
export default Skills;